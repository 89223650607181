.paga404body{

    width: 50%;
    margin: auto;
    margin-top: 7%;
    text-align: center

}
.para-page-error{
    font-size: 190%
}