

@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap');

.email-signup {
  padding-left : 0px;
}
/* .navbar {
  background-color: #639ED6 !important; 
} */

@font-face {
  font-family: 'Proxima Nova - Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Light'), url('../fonts/Proxima\ Nova\ Light.woff') format('woff');
}


@font-face {
  font-family: 'Proxima Nova Lt';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Nova Semibold'), url('../fonts/Proxima\ Nova\ Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Akrobat Bold';
  font-style: normal;
  font-weight: normal;
  src:  url('../fonts/Akrobat-Bold.woff');
}

@font-face {
  font-family: 'PolySans';
  font-style: normal;
  font-weight: normal;
  src:  url('../fonts/PolySans-Median.otf');
}

@font-face {
  font-family: 'PolySans Bold';
  font-style: normal;
  font-weight: normal;
  src:  url('../fonts/PolySans-Bulky.otf');
}

@font-face {
  font-family: 'PolySans Neutral';
  font-style: normal;
  font-weight: normal;
  src:  url('../fonts/PolySans-Neutral.otf');
}

.converter-card-radius {
    border-radius: 10px;
    -webkit-box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
    box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
}

@media screen and (max-width: 1087px){
  #navMenu {
      background-color: #6666cc;
      box-shadow: 0 8px 16px rgba(10,10,10,0.1);
      padding: 0.5rem 0;
  }
  .email-signup {
    text-align: left;
  }
}


.navbar-item img {
    max-height: 2.5rem;
}

/*@media screen and (min-width: 1088px){
  .navbar-dropdown {
    display: block;
  }
}*/

.dashboard-wrapper {
  margin-left: 2%;
  padding-top: 50px;
  padding-right: 12%;
  padding-left: 12%
}
/* .hero.is-slant:before {
  z-index: 0 !important;
  } */

footer.footer-light-left{
  padding: 0 20px 15px 20px !important;
}

.navbar-wrapper{
  position: relative !important;
}

.featured-feed-post .author-avatar img {
  height: auto;
  border-radius: 0;
}

.featured-feed-post img {
  display: block;
  height: auto;
  width: auto;
  border-radius: 8px;
  margin-top: -40px;
  box-shadow: none;
}

h1{
  font-size: 2em;
}

h2{
  font-size: 1.2em;
}

p{
  margin: 0 0 10px;
}

footer.footer {
  margin-left: 0px !important;
}

section{
  position: relative;
}


#main-hero {
  max-height: none;
}

.new-bg {
  background: linear-gradient(242.17deg, #6FA8DC -105.25%, #041B42 100%);
}

.new-nav-btn {
  
  background: #6FA8DC !important;
  box-shadow: 0px 8px 34px rgba(111, 168, 220, 0.6) !important;
  border-radius: 5px 0px !important;
}

.new-nav-btn > span {
  display: block;
  height: 34px;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;

  text-align: center;

  color: whitesmoke;
}

.new-brand {
  height: 58px;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 58px;


  color: #FFFFFF;
}

.brand-logo {
  display: inline-block;
  background: url('../images/afriex-logo-2.jpeg');
}

.clean-title {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 57px;
}

#hero-img {
  position: relative;
  margin-bottom: -50px;
  z-index: 0;
}

.hero-subtitle {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  margin-top: 7%;
}

@media (min-width: 768px) {
  .features {
    height: 430px;
  }

  .feature-container {
    margin-top: 1.5%;
    /* margin-left: 4.5%; */
    padding: 4%;
  }

  .features .icon-container { 
    left: 20.28%;
    margin-bottom: 1px !important;
  }

  .feature-container h3 { 
    /* left: 20.28%; */
    margin-bottom: 1px !important;
  }

  .feature-container .rule { 
    left: 20.28%;
    margin-bottom: 1px !important;
  }

  .feature-container p { 
    /* left: 20.28%; */
    padding-right: 1px !important;
  }

  .testimonies {
    left: 17.44%;
  }

  .testimonies .testimony {
    margin: 3%;
  
    width: 420px;
    height: 500px;
  }

  .testimony > p { 
    left: 11.9%;
  }

  .testimony .quote-mini {
    left: 11.9%;
  }

  .testimony .avater {
    margin-top: 60px !important;
    margin-left: 15px !important;
  }

  .new-footer {
    /* margin-top: -30%; */
    z-index: 9999999;
  }

  .close-space-footer {
    margin-bottom: -300px;
  }

  .testimonial-section h3 {
    left: 7.42% !important;
  }

  .testimony span {
    margin-left: 25% !important;
  }

  
}

.features {
  position: relative;
  z-index: 999999;
  margin-left: 5%;
  margin-right: 5%;

  background: #FFFFFF;
  box-shadow: 0px 10px 34px rgba(210, 210, 210, 0.5);
  border-radius: 10px;
}

.features .icon-container {
  position: relative;
  left: 5.28%;
  right: 87.99%;
  top: 20.71%;
  bottom: 67.14%;
  height: 72px;
  width: 72px;
  margin-bottom: 15px;

  /* blue */

  background: #f1f6fb;
  mix-blend-mode: normal;
  border-radius: 6px;
}

.feature-container h3 {
  position: relative;
  height: 21px;
  /* left: 5.28%;
  right: 81.38%; */
  top: 36%;

  margin-bottom: 15px;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  /* GREY */

  color: #444F60;
} 

.feature-container .rule {
  position: relative;
  left: 5.28%;
  right: 88.94%;
  top: 50.95%;
  bottom: 48.1%;

  margin-bottom: 15px;

  height: 4px;
  width: 64px;

  /* blue */

  background: #6FA8DC;
}

.feature-container p {
  position: relative;
  /* left: 5.28%;
  right: 69.85%; */
  top: 55.19%;
  bottom: 20.95%;

  margin-bottom: 15px;

  padding-right: 25px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */


  /* GREY */

  color: #444F60;
}

.icon-container > img {
  position: relative;
  left: 20.29%;
  right: 89.26%;
  top: 25.29%;
  bottom: 71.71%;
}

.service-layout-1 {
  /* margin-top: 5%; */
  margin-right: 4%;
}

.service-layout-1 h3 {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 64px;
  /* identical to box height, or 160% */


  color: #FFFFFF;
}

.service-layout-1 p {
  margin-top: 5%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */


  color: #FFFFFF;
}

.service-layout-1 img {
  margin-top: -7%;
}

.testimonial-section h3 {
  position: relative;
  height: 104px;
  left: 0.42%;
  right: 79.48%;
  top: -25%;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  /* or 120% */


  color: #FFFFFF;
}

.testimonial-section #quote-img {
  position: relative;
  left: 0%;
  right: 89.83%;
  top: 3.09%;
  bottom: 54.33%;
}

.testimonial-section #side-rect {
  position: relative;
  left: 29.76%;
  right: 20.33%;
  top: 0%;
  bottom: 0%;
  height: 950px;
  width: 810px;
}

.testimonies {

  position: relative;
  left: 42.44%;
  right: 56.69%;
  top: -50.8%;
  bottom: 21.65%;
}

.testimonies .testimony {
  position: relative;
  left: -40.44%;
  right: 56.69%;
  bottom: 21.65%;

  margin: 3%;

  border-radius: 6px;

  background: rgba(255, 255, 255, 0.95);
  box-shadow: 12px 12px 40px rgba(121, 121, 121, 0.3);
  backdrop-filter: blur(27.1828px);
/* Note: backdrop-filter has minimal browser support */
}

.testimonies .testimony-active {

  background: #6FA8DC;
  
  backdrop-filter: blur(27.1828px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 6px;
}

.testimony > p {
  position: relative;
  left: 2.9%;
  right: 14.29%;
  top: 8.6%;
  bottom: 28.2%;

  margin-bottom: 5%;
  /* width: 340px; */

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;
  /* or 200% */


  color:#5B6C94;;
}

.testimony-active > p {
  color: #FFFFFF;
}

.testimony .quote-mini {
  position: relative;
  left: 2.9%;
  right: 83.81%;
  top: 46%;

  margin-top: 80px;
}

/* @media only screen and (min-width: 510px) and (max-width: 765px) {
  .testimony .avater {
    display: inherit !important
  }
} */

.testimony .avater {
  position: relative;
  border-radius: 50%;
  width: 58px;
  height: 58px;

  margin-top: 20px;
  margin-left: 15px;
}

.testimony span {
  margin-left: 35%;
  margin-top: -13%;
  display: block;

  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  /* identical to box height, or 200% */

  color: #031D5B;
  
}

.testimony-active span {
  color: #FFFFFF;
}

#footer-link {
  color: #f1f6fb !important
}

.footer-texts {
  font-family: Rubik !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 32px !important;

  text-align: center !important;

  color: whitesmoke !important;

  opacity: 0.6;

  display: inline-block;

  padding-left: 10px;
}


@media screen and (max-width: 768px){

  .play-store {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.navbar-item {
  color: white;
}