input.reset-input {
  margin-bottom: 20px;
}

input.reset-input:hover {
  border: 1px solid#0075FF;
  outline: 1px solid #0075FF;
}

.top-nav {
  padding: 25px 30px;
  z-index: 100;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  background-color: #FFFFFF;
}

.top-nav .nav-wrap {
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.top-nav .links {
  margin-left: 25px;
  display: block;
}

.top-nav .links li {
  display: inline-block;
  margin-left: 25px;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
}

.top-nav .nav-right {
  margin-left: auto;
}

.logo-wrap {
  max-width: 20%;
}

.login-logo {
  max-width: 70%;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 800px) {  
  .top-nav {
    padding: 18px 25px;
  }
  
  .top-nav .nav-wrap {
    width: 88%;
  }
  
  .logo-wrap {
    max-width: 35%;
  }
  
  .login-logo {
    max-width: 52%;
    padding: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 500px ) {  
  .top-nav {
    padding: 15px 10px;
  }
  
  .top-nav .nav-wrap {
    width: 90%;
  }
  
  .logo-wrap {
    max-width: 40%;
  }
  
  .login-logo {
    max-width: 75%;
    padding: 0;
    margin: 0;
  }
}

.nav-right .download-btn {
  padding: 10px 30px;
  background-color: #0075FF;
  color: #FFFFFF;
  font-weight: 700;
  border-radius: 20px;
  font-size: 0.9rem;
}

.page-formtitle {
  font-family: "PolySans Bold";
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 1.7rem;
  font-weight: 600;
}

.page-formsubtitle {
  font-size: 0.98rem;
  font-weight: 300;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 0;
  font-family: "PolySans Neutral";
}

@media only screen and (max-width: 1200px) {
  .page-formtitle {
    font-size: 1.4rem;
    font-weight: 500;
  }
  
  .page-formsubtitle {
    font-size: 0.9rem;
    padding: 0;
    margin-top: 3px;
  }
}

@media only screen and (max-width: 1000px) {
  .page-formtitle {
    font-size: 1.38rem;
    font-weight: 500;
  }
  
  .page-formsubtitle {
    font-size: 0.85rem;
    padding: 0;
    margin-top: 3px;
  }
}

@media only screen and (max-width: 800px ) {
  .page-formtitle {
    font-size: 1.35rem;
    font-weight: 500;
  }
  
  .page-formsubtitle {
    font-size: 0.85rem;
    padding: 0;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .page-formtitle {
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  .page-formsubtitle {
    font-size: 0.8rem;
    padding: 0;
    margin-top: 2px;
  }
}

#remember {
  margin-left: -12px;
}

.page-aside-image#login-page-image {
  background: url('https://s3.eu-west-3.amazonaws.com/afriex/login-image.jpg');
}

.recovery-input-item {
  text-align: left !important;
}

#forgot-pass-link {
  height: 23px;
  color: #3f4d60;
  font-family: "Proxima Nova - Regular";
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
}

@media only screen and (max-width: 768px ) {

  .page-title#login-title {
     margin-top: 90px !important;
  }

  #forgot-pass {
     margin-top: 25px !important;
  }
}

@media only screen and (min-width: 768px ) {
  .recovery-input-item {
     margin-left: 35% !important;
     margin-right: 35% !important;
  }
}

.form-wrap {
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-wrap .form {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px;
  padding: 40px 45px;
  width: 30vw;
}

@media only screen and (max-width: 1200px) {
  .form-wrap .form {
    padding: 26px 40px;
    width: 50vw;
  }
}

@media only screen and (max-width: 1000px) {
  .form-wrap .form {
    padding: 25px 40px;
    width: 50vw;
  }
}

@media only screen and (max-width: 800px) {
  .form-wrap .form {
    padding: 26px 40px;
    width: 55vw;
  }
}

@media only screen and (max-width: 700px) {
  .form-wrap .form {
    padding: 25px 35px;
    width: 70vw;
  }
}

@media only screen and (max-width: 500px) {
  .form-wrap .form {
    padding: 25px 30px;
    width: 80vw;
  }
}

@media only screen and (max-width: 350px) {
  .form-wrap .form {
    padding: 20px 25px;
    width: 90vw;
  }
}

.form-wrap .form:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.requirements-header {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "PolySans Bold";
}

.password-requirements .requirements {
  font-size: 0.92rem;
  margin-bottom: 5px;
  font-weight: 500;
  font-style: normal;
  font-family: "PolySans Neutral";
}

@media only screen and (max-width: 1200px) {
  .requirements-header {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .password-requirements .requirements {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 1000px) {
  .requirements-header {
    font-size: 0.95rem;
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .password-requirements .requirements {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 800px) {
  .requirements-header {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .password-requirements .requirements {
    font-size: 0.82rem;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .requirements-header {
    font-size: 0.9rem;
    font-weight: 800;
    margin-bottom: 4px;
  }
  
  .password-requirements .requirements {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }
  
  .form-down p {
    margin-top: 0.9rem;
  }
}

.button.reset-btn {
  background-color: #0075FF;
  height: 42px;
  width: 100%;
  margin-top: 15px;
  color: #FFFFFF;
  font-weight: bold;
  font-family: "PolySans Neutral";
}

.form-down {
  margin-top: 15px;
  font-family: "PolySans Neutral";
}

.form-down span {
  font-size: 1rem;
  display: block;
  text-align: center;
}

.form-down a.support {
  font-weight: 500;
  text-decoration: underline;
}

@media only screen and (max-width: 1000px) {
  .form-down span {
    font-size: 0.92rem;
  }
  .button.reset-btn {
    font-size: 0.95rem;
  }
}

@media only screen and (max-width: 800px) {  
  .form-down span {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 500px ) {  
  .form-down span {
    font-size: 0.8rem;
  }
  
  .button.reset-btn {
    height: 38px;
    font-size: 0.93rem;
  }
}
