.cust-input-item {
  margin-top: 30px;
  margin-left: 10%;
  margin-right: 10%;
}

.cust-label {
  width: 39px;
  height: 28px;
  color: #111111;
  font-family: "Proxima Nova Lt";
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}

.cust-input {
  height: 42px !important;
  border-radius: 4px !important;
  border: 1px solid #cfd2d7 !important;
  background-color: #ffffff !important;
  padding-left: 20px !important;
  color: #333333 !important;
  font-family: "Proxima Nova - Regular" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}
.button-container {
  width: 392px;
  height: 70px;
  border-radius: 50px;
  align-self: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  margin-top: 25px;
}
.green-button {
  width: 380px;
  height: 56px;
  left: 11px;
  top: 10px;
  position: absolute;
  background: #16db93;
  border-radius: 8px;
}
.blue-button {
  width: 380px;
  height: 56px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 20px;
  padding-bottom: 20px;

  position: absolute;
  background: #0065ff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}
.download-text {
  text-align: center;
  color: white;
  font-size: 16px;
  font-family: "PolySans";
  font-weight: 700;
  line-height: 16px;
  word-wrap: break-word;
}

.reg-form-wrap .form input {
  margin-bottom: 0.65rem;
}

@media only screen and (max-width: 500px) {
  .cust-input {
    height: 38px !important;
  }
  .blue-button {
    width: 317px !important;
  }
  .green-button {
    width: 317px !important;
  }
  .button-container {
    width: 317px !important;
  }
}

.has-error > input {
  border: 1px solid #ff3e2e !important;
}

.cust-input::placeholder {
  /* width: 114px; */
  height: 25px;
  color: #b2b8bf;
  font-family: "Proxima Nova - Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.cust-validation-error {
  color: #ff3e2e;
  font-family: "Proxima Nova - Regular";
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 4px;
}

.cust-button {
  width: 256px;
  height: 56px;
  border-radius: 50px;
  background-image: linear-gradient(
    to right,
    #80c4ff 0%,
    #329fff 50%,
    #ab00ff 100%
  );
  text-align: center;
  margin-right: 10%;
}

.cust-button span {
  width: 89px;
  height: 25px;
  color: #ffffff;
  font-family: "Proxima Nova - Regular";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1.06px;
  text-transform: uppercase;
}

.resend-email-button {
  margin-right: 0;
}

.resend-email-button span {
  width: 136px;
  height: 25px;
  color: #ffffff;
  font-family: "Proxima Nova - Regular";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1.06px;
  text-transform: uppercase;
}

.form-footer {
  margin-left: 0%;
  margin-right: 10%;
  margin-top: 40px;
  text-align: left;
}

.register-footer {
  margin-top: 20px;
  text-align: left;
}

.form-footer p {
  color: #657180;
  font-family: "PolySans Neutral";
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 30px;
}

.form-footer a {
  color: #0075ff;
  font-family: "PolySans Neutral";
  text-decoration: underline;
}

.form-footer-extra {
  margin-top: 15px;
}

.form-footer-extra #extra-link {
  color: #0388ef;
  text-decoration: none;
}

.register-back {
  margin-top: 16px;
}

.register-back a {
  width: 109px;
  height: 23px;
  color: #333333;
  font-family: "Proxima Nova - Regular";
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline;
}

.page-aside {
  background: linear-gradient(173.34deg, #2ea6ff 19.93%, #0340dd 90.28%);
  padding-top: 8%;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
}

.page-aside-label {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 61.5929px;
  line-height: 112%;
  position: absolute;
  left: 7.25%;
  right: 7.17%;
  top: 12.07%;
  bottom: 75.84%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.page-aside-intro-image {
  max-width: 60%;
  margin-top: -2%;
}

.page-aside-card-image {
  margin-top: 5%;
  margin-right: -3%;
  height: 35rem;
}

.section-one {
  align-items: center;
  display: flex;
  align-items: center;
  font-family: "Polysans";
  width: 100vw;
  /* height: 90vh; */
  padding-left: "10px";
  padding-right: "10px";
  flex-direction: column;
}
@media (max-width: 768px) {
  .checkmark-img {
    width: 100%;
    max-width: 300px;
  }
}

@media (min-width: 769px) {
  .checkmark-img {
    width: 500px;
  }
}

.page-preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  font-family: "Polysans";
  width: 100vw;
  height: 90vh;
  padding-left: "10px";
  padding-right: "10px";
  flex-direction: column;
}

.logo-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.logo-image-box .register-logo {
  margin-left: 12%;
}

@media only screen and (max-width: 768px) {
  .page-aside-card-image,
  .page-aside-card-image {
    display: none;
  }

  .page-title {
    color: #333333;
    font-family: "Akrobat Bold";
    font-size: 40px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 50px;
    text-align: center;
  }

  .page-subtitle {
    color: #333333;
    font-family: "Proxima Nova Lt";
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 20px;
    text-align: center;
  }

  .page-subtitle-2 {
    color: #657180;
    font-family: "Proxima Nova - Regular";
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 8px;
    text-align: center;
  }

  .register-illust {
    width: 277px;
    height: 150px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 600px) {
  .page-title {
    width: 112px;
    height: 36px;
    color: #333333;
    font-family: "Akrobat Bold";
    font-size: 40px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 25px;
    margin-left: 10%;
  }

  .page-subtitle {
    color: #111111;
    font-family: "Akrobat Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin-top: 169px;
    text-align: center;
  }

  .register-subtitle {
    margin-top: 30px !important;
  }

  .page-subtitle-2 {
    color: #333333;
    font-family: "Proxima Nova - Regular";
    font-size: 22px;
    font-weight: 400;
    line-height: 35px;
    margin-top: 10px;
    text-align: center;
  }

  .register-illust {
    width: 277px;
    height: 150px;
    margin-top: 112px;
  }

  .form-footer p {
    text-align: left;
  }

  .form-footer-extra {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .page-title {
    display: none;
  }

  .verify-email-title {
    display: block !important;
    width: 112px;
    height: 36px;
    color: #333333;
    font-family: "Akrobat Bold";
    font-size: 40px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 25px;
    margin-left: 10%;
  }

  .page-title-white {
    width: 101px;
    height: 51px;
    color: #fff8f8;
    font-family: "Akrobat Bold";
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 50px;
    text-transform: uppercase;
    padding-top: 30px;
    padding-left: 19px;
  }

  .page-aside-image p {
    width: 384px;
    height: 160px;
    color: #ffffff;
    font-family: "Proxima Nova - Regular";
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    padding-top: 750px;
    padding-left: 48px;
    padding-bottom: 46px;
  }
}

@media only screen and (min-width: 770px) and (max-width: 900px) {
  .page-aside-image {
    width: 400px;
  }
}

.phone-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 45px;
  border-radius: 4px !important;
  border: 1px solid #cfd2d7 !important;
  background-color: #ffffff !important;
  margin-bottom: 0.65rem;
}

.phone-container .phone-input {
  flex: 2;
  height: 100%;
  border: 0;
}

.reg-form-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.reg-form-wrap .form {
  width: 40vw;
}

@media only screen and (max-width: 1200px) {
  .reg-form-wrap .form {
    padding: 26px 40px;
    width: 50vw;
  }
}

@media only screen and (max-width: 1000px) {
  .reg-form-wrap .form {
    padding: 25px 40px;
    width: 50vw;
  }
}

@media only screen and (max-width: 800px) {
  .reg-form-wrap .form {
    padding: 26px 40px;
    width: 55vw;
  }
}

@media only screen and (max-width: 700px) {
  .reg-form-wrap .form {
    padding: 25px 35px;
    width: 70vw;
  }
}

@media only screen and (max-width: 500px) {
  .reg-form-wrap .form {
    padding: 25px 30px;
    width: 85vw;
  }
}

@media only screen and (max-width: 350px) {
  .reg-form-wrap .form {
    padding: 20px 25px;
    width: 90vw;
  }
}

@media only screen and (max-width: 350px) {
  .reg-form-wrap .form .page-formtitle {
    padding-top: 15vh !important;
    width: 80vw;
  }
}

.reg-form-wrap .form .page-formtitle {
  font-family: "PolySans";
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
}

.reg-form-wrap .form .page-formsubtitle {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 0;
  font-family: "PolySans Neutral";
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .reg-form-wrap .form .page-formtitle {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .reg-form-wrap .form .page-formsubtitle {
    font-size: 0.92rem;
    padding: 0;
    margin-top: 3px;
  }

  .reg-form-wrap .form label {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1000px) {
  .reg-form-wrap .form .page-formtitle {
    font-size: 1.38rem;
    font-weight: 500;
  }

  .reg-form-wrap .form .page-formsubtitle {
    font-size: 0.85rem;
    padding: 0;
    margin-top: 3px;
  }

  .reg-form-wrap .form label {
    font-size: 0.95rem;
  }
}

@media only screen and (max-width: 800px) {
  .reg-form-wrap .form .page-formtitle {
    font-size: 1.35rem;
    font-weight: 500;
  }

  .reg-form-wrap .form .page-formsubtitle {
    font-size: 0.85rem;
    padding: 0;
    margin-top: 5px;
  }

  .reg-form-wrap .form label {
    font-size: 0.9rem;
  }

  .form-footer p {
    font-size: 0.87rem;
  }
}

@media only screen and (max-width: 500px) {
  .reg-form-wrap .form .page-formtitle {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .reg-form-wrap .form .page-formsubtitle {
    font-size: 0.8rem;
    padding: 0;
    margin-top: 2px;
  }

  .reg-form-wrap .form label {
    font-size: 0.85rem;
  }

  .phone-container {
    height: 40px;
  }

  .form-footer p {
    font-size: 0.82rem;
  }
}
