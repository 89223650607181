.hero.is-theme-primary,
.section.is-theme-primary {
    background-color: #6fa8dc
}

.hero.is-theme-primary .title,
.section.is-theme-primary .title {
    color: #fff
}

.hero.is-theme-primary .subtitle,
.section.is-theme-primary .subtitle {
    color: #fff
}

.navbar-wrapper {
    width: 100%;
    height: 4.6rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    transition: all 0.4s
}

.navbar-wrapper.is-transparent .navbar-item.is-slide {
    border-bottom: 1px solid transparent
}

.navbar-wrapper.is-transparent .navbar-item.is-slide:after {
    content: '';
    display: block;
    width: 0;
    min-width: 0;
    height: 1.6px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #6fa8dc;
    transition: width .3s
}

.navbar-wrapper.is-transparent .navbar-item.is-slide:hover:after {
    width: 100%
}

.navbar-wrapper .navbar-brand {
    -ms-flex-align: center;
    align-items: center
}

.navbar-wrapper .navbar-brand img {
    height: 28px
}

.navbar-wrapper .navbar-item:hover {
    color: #999;
    background: transparent
}

.navbar-wrapper .navbar-item:hover a {
    background: transparent
}

.navbar-wrapper.navbar-light .icon-box-toggle span i {
    background: #fff!important
}

.navbar-wrapper.navbar-light .navbar-item {
    color: #fff
}

.navbar-wrapper.navbar-light .navbar-item.is-slide {
    border-bottom: 1px solid transparent
}

.navbar-wrapper.navbar-light .navbar-item.is-slide:after {
    content: '';
    display: block;
    width: 0;
    min-width: 0;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    transition: width .3s
}

.navbar-wrapper.navbar-light .navbar-item.is-slide:hover:after {
    width: 100%
}

.account {
    position: relative;
    right: 20px;
    top: 5px;
    margin-left: 30px
}

.account div a {
    color: #66676b!important
}

.account div a i {
    position: relative;
    top: 3px;
    left: 10px;
    font-size: 7px!important;
    color: #66676b
}

.menu-toggle {
    font-size: 20px;
    color: #666;
    line-height: 48px;
    text-align: center;
    background: transparent;
    display: block;
    width: 24px;
    height: 26px;
    cursor: pointer;
    padding: 0;
    margin: 0 14px;
    transition: opacity 0.4s;
    opacity: 1;
    position: relative;
    top: 2px
}

.menu-toggle .icon-box-toggle {
    height: 100%;
    width: 100%;
    background-color: initial;
    position: relative;
    display: block;
    width: 30px;
    height: 30px
}

.menu-toggle .icon-box-toggle.active>span.rotate {
    -webkit-transform: rotate(90deg);
    transform: translate(0, 0) rotate(90deg)
}

.menu-toggle .icon-box-toggle.active>span>i.icon-line-center {
    visibility: hidden;
    width: 1px;
    height: 3px;
    left: 70%
}

.menu-toggle .icon-box-toggle.active>span>i.icon-line-bottom {
    margin: -2px 0 0 -10px;
    left: 50%;
    top: 12px;
    -webkit-transform: rotate(135deg);
    transform: translate(0, 0) rotate(135deg)
}

.menu-toggle .icon-box-toggle.active>span>i.icon-line-top {
    margin: -2px 0 0 -10px;
    left: 50%;
    top: 12px;
    -webkit-transform: rotate(45deg);
    transform: translate(0, 0) rotate(45deg)
}

.menu-toggle .icon-line-center {
    position: absolute;
    width: 20px;
    height: 2px;
    background: #A9ABAC;
    margin: -1px 0 0 -10px;
    left: 50%;
    top: 11px;
    transition: all .2s ease
}

.menu-toggle .icon-line-top {
    position: absolute;
    width: 20px;
    height: 2px;
    background: #A9ABAC;
    margin: -3px 0 0 -10px;
    left: 50%;
    top: 7px;
    transition: all .2s ease
}

.menu-toggle .icon-line-bottom {
    position: absolute;
    width: 20px;
    height: 2px;
    background: #A9ABAC;
    margin: 2px 0 0 -10px;
    left: 50%;
    top: 14px;
    transition: all .2s ease
}

.section.section-feature-grey {
    background-color: #fbfbfb
}

.section.no-margin {
    margin: 0!important;
    padding-top: 0!important;
    padding-bottom: 0!important
}

.section .container {
    z-index: 1
}

.section .content-wrapper {
    padding-top: 40px
}

.section .section-title-wrapper {
    position: relative;
    padding: 40px 20px
}

.hero.is-theme-primary {
    background-color: #6fa8dc
}

.hero.is-theme-primary .title {
    color: #fff
}

.hero.is-theme-primary .subtitle {
    color: #fff
}

.hero.is-light-grey {
    background-color: #EFF4F7;
    background-image: linear-gradient(141deg, #d0e0ec 0%, #f5f7fa 71%, white 100%)
}

.hero.is-light-grey .subtitle {
    margin-top: 0!important;
    padding-right: 20%;
    line-height: 1.6!important
}

.hero-body {
    background-size: cover!important;
    background-repeat: no-repeat!important
}

.hero-body .title.page-title {
    font-size: 3.5rem
}

.hero-body .subtitle.page-subtitle {
    font-size: 1.8rem
}

footer.footer-light-left {
    padding: 5rem 1.5rem!important
}

footer.footer-light-left .footer-nav-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-align: center;
    align-items: center
}

footer.footer-light-left .footer-nav-right .footer-nav-link {
    margin: 0 10px;
    padding: 20px 0;
    color: #444F60!important;
    border-bottom: 2px solid transparent
}

footer.footer-light-left .footer-nav-right .footer-nav-link:hover {
    color: #999!important;
    border-bottom: 2px solid #999
}

footer.footer-light-left .footer-nav-right .footer-nav-link.is-active {
    color: #6fa8dc!important;
    border-bottom: 2px solid #6fa8dc!important
}

footer.footer-light-left .level-item .icon {
    color: #cecece;
    transition: all 0.5s
}

footer.footer-light-left .level-item .icon:hover {
    color: #6fa8dc
}

footer.footer-light-left .moto {
    color: #999
}

@media (max-width:768px) {
    .footer-nav-right {
        margin-top: 0!important
    }
}

.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: both
}

@-webkit-keyframes translateLeft {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes translateLeft {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@-webkit-keyframes translateDown {
    from {
        -webkit-transform: translate3d(0, -100px, 0);
        transform: translate3d(0, -100px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes translateDown {
    from {
        -webkit-transform: translate3d(0, -100px, 0);
        transform: translate3d(0, -100px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@-webkit-keyframes translateUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes translateUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeInLeft {
    from {
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
        opacity: 0
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes fadeInLeft {
    from {
        -webkit-transform: translate3d(20px, 0, 0);
        transform: translate3d(20px, 0, 0);
        opacity: 0
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInRight {
    from {
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0);
        opacity: 0
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes fadeInRight {
    from {
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0);
        opacity: 0
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.preFadeInRight {
    opacity: 0
}

.fadeInRight {
    opacity: 0;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}

@-webkit-keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInDown {
    from {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@keyframes fadeInDown {
    from {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeOutUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 0
    }
}

@keyframes fadeOutUp {
    from {
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 0
    }
}

@-webkit-keyframes levitate {
    from {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px)
    }
    to {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@keyframes levitate {
    from {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px)
    }
    to {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

.levitate {
    -webkit-animation-name: levitate;
    animation-name: levitate;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}

@-webkit-keyframes gelatine {
    from,
    to {
        -webkit-transform: scale(1, 1)
    }
    25% {
        -webkit-transform: scale(.9, 1.1)
    }
    50% {
        -webkit-transform: scale(1.1, .9)
    }
    75% {
        -webkit-transform: scale(.95, 1.05)
    }
}

@keyframes gelatine {
    from,
    to {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1)
    }
    25% {
        -webkit-transform: scale(.9, 1.1);
        transform: scale(.9, 1.1)
    }
    50% {
        -webkit-transform: scale(1.1, .9);
        transform: scale(1.1, .9)
    }
    75% {
        -webkit-transform: scale(.95, 1.05);
        transform: scale(.95, 1.05)
    }
}

@-webkit-keyframes unzoom {
    from,
    to {
        -webkit-transform: scale(1, 0)
    }
    25% {
        -webkit-transform: scale(.9, 1.1)
    }
    50% {
        -webkit-transform: scale(1.1, 0)
    }
}

@keyframes unzoom {
    from,
    to {
        -webkit-transform: scale(1, 0);
        transform: scale(1, 0)
    }
    25% {
        -webkit-transform: scale(.9, 1.1);
        transform: scale(.9, 1.1)
    }
    50% {
        -webkit-transform: scale(1.1, 0);
        transform: scale(1.1, 0)
    }
}

@-webkit-keyframes dotPulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
        opacity: 0
    }
}

@keyframes dotPulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
        opacity: 0
    }
}

@-webkit-keyframes entrance {
    from {
        -webkit-transform: scale(0)
    }
    25% {
        -webkit-transform: scale(1.05)
    }
    50% {
        -webkit-transform: scale(.95)
    }
    75% {
        -webkit-transform: scale(1.05)
    }
    to {
        -webkit-transform: none
    }
}

@keyframes entrance {
    from {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    25% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05)
    }
    50% {
        -webkit-transform: scale(.95);
        transform: scale(.95)
    }
    75% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05)
    }
    to {
        -webkit-transform: none;
        transform: none
    }
}

@-webkit-keyframes scaleIn {
    from {
        -webkit-transform: scale(0)
    }
    to {
        -webkit-transform: scale(1)
    }
}

@keyframes scaleIn {
    from {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.scaleIn {
    -webkit-animation: scaleIn 0.5s;
    animation: scaleIn 0.5s;
    -webkit-animation-timing-function: cubic-bezier(.645, .045, .355, 1);
    animation-timing-function: cubic-bezier(.645, .045, .355, 1)
}

@-webkit-keyframes scaleInCircle {
    from {
        -webkit-transform: scale(0)
    }
    to {
        -webkit-transform: scale(7)
    }
}

@keyframes scaleInCircle {
    from {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    to {
        -webkit-transform: scale(7);
        transform: scale(7)
    }
}

.scaleInCircle {
    -webkit-animation: scaleInCircle 0.6s;
    animation: scaleInCircle 0.6s;
    -webkit-animation-timing-function: cubic-bezier(.645, .045, .355, 1);
    animation-timing-function: cubic-bezier(.645, .045, .355, 1)
}

@-webkit-keyframes scaleOut {
    from {
        -webkit-transform: scale(1)
    }
    to {
        -webkit-transform: scale(0)
    }
}

@keyframes scaleOut {
    from {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    to {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
}

@-webkit-keyframes pulsate {
    0% {
        -webkit-transform: scale(.1, .1);
        opacity: 0
    }
    50% {
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0
    }
}

.delay-2 {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.delay-4 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.side-navigation-menu {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background: #344258;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    overflow: hidden;
    -webkit-transform: translateX(30000px);
    transform: translateX(30000px);
    transition: width .05s linear;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    z-index: 1000
}

.side-navigation-menu.is-active {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.side-navigation-menu .category-menu-wrapper {
    height: 100%;
    position: relative;
    width: 80px;
    background: #344258
}

.side-navigation-menu .category-menu-wrapper ul.categories li {
    height: 60px
}

.side-navigation-menu .category-menu-wrapper ul.categories li.square-logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center
}

.side-navigation-menu .category-menu-wrapper ul.categories li.square-logo img {
    max-height: 28px
}

.side-navigation-menu .category-menu-wrapper ul.categories li.is-active {
    background: #212a38
}

.side-navigation-menu .category-menu-wrapper .author {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%
}

.side-navigation-menu .category-menu-wrapper .author li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative
}

.side-navigation-menu .category-menu-wrapper .author a {
    position: relative;
    z-index: 4
}

.side-navigation-menu .category-menu-wrapper .author .main-menu-author {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    margin: 0 auto;
    margin-bottom: 10px;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s
}

.side-navigation-menu .category-menu-wrapper .author .main-menu-author:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.side-navigation-menu .navigation-menu-wrapper {
    height: 100%;
    width: 100%;
    position: relative
}

.side-navigation-menu .navigation-menu-wrapper ul.navigation-menu {
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 400px;
    list-style: none;
    list-style-type: none;
    overflow: auto
}

.side-navigation-menu .navigation-menu-wrapper ul.navigation-menu::-webkit-scrollbar {
    width: 10px
}

.side-navigation-menu .navigation-menu-wrapper ul.navigation-menu::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(255, 255, 255, .1)
}

.side-navigation-menu .navigation-menu-wrapper .navigation-menu-header {
    height: 60px;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 25px;
    text-transform: uppercase;
    font-weight: 300;
    color: #fcfcfc;
    letter-spacing: 3px;
    font-size: 92%
}

.side-navigation-menu .navigation-menu-wrapper .navigation-menu-header .menu-toggle .icon-box-toggle span i {
    background: #fff
}

.side-navigation-menu .navigation-menu-wrapper .navigation-menu-header .navigation-close {
    opacity: 0
}

.side-navigation-menu .navigation-menu-wrapper .navigation-menu li a span {
    margin-right: 20px;
    color: #fff;
    font-size: 22px
}

.side-navigation-menu .navigation-menu-wrapper .navigation-menu li a {
    padding: 18px 25px;
    display: block;
    text-decoration: none;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.side-navigation-menu .navigation-menu-wrapper .navigation-menu li a.parent-link {
    height: 60px!important;
    position: relative
}

.side-navigation-menu .navigation-menu-wrapper .navigation-menu li a:hover {
    background: #3d4e68
}


.side-navigation-menu .navigation-menu-wrapper li {
    position: relative
}

@-webkit-keyframes page-loader-inner {
    0%,
    40% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@keyframes page-loader-inner {
    0%,
    40% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@-webkit-keyframes page-loader-outer {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    100%,
    40% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }
}

@keyframes page-loader-outer {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    100%,
    40% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }
}

.button {
    transition: all 0.5s
}

.button.button-signup {
    padding: 22px 40px!important
}

.button.button-signup.btn-outlined.is-bold {
    border-width: 2px;
    font-weight: 600
}

.button.button-cta {
    padding: 22px 40px!important
}

.button.button-cta.btn-outlined.is-bold {
    border-width: 2px;
    font-weight: 600
}

.button.no-lh {
    line-height: 0!important
}

.button.btn-align {
    padding: 3px 13px 6px 13px
}

.button.raised:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2)!important;
    opacity: .8
}

.button.btn-outlined {
    background: transparent
}

.button.btn-outlined.light-btn {
    border-color: #fff;
    color: #fff
}

.button.btn-outlined.light-btn:hover {
    background-color: #fff;
    color: #444F60
}

.button.btn-outlined.is-bold {
    border-width: 2px;
    font-weight: 600
}

.button:focus {
    border-color: #ccc
}

.button i {
    position: relative;
    top: 1px;
    padding-right: 8px
}

.button.is-fullwidth {
    display: block!important;
    width: 100%!important;
    text-align: center!important
}

.button.primary-btn {
    outline: none;
    border-color: #6fa8dc;
    background-color: #6fa8dc;
    color: #fff;
    transition: all 0.5s
}

.button.primary-btn:hover {
    color: #fff
}

.button.primary-btn.raised:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 178, 137, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 178, 137, .2)!important;
    opacity: .9 .5
}

.button.primary-btn.btn-outlined {
    border-color: #6fa8dc;
    color: #6fa8dc;
    background-color: transparent
}

.button.primary-btn.btn-outlined:hover {
    color: #fff;
    background-color: #6fa8dc
}

.button.primary-btn:focus {
    border-color: #6fa8dc
}

.button.secondary-btn {
    outline: none;
    border-color: #344258;
    background-color: #344258;
    color: #fff;
    transition: all 0.5s
}

.button.secondary-btn:hover {
    color: #fff
}

.button.secondary-btn.raised:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 178, 137, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 178, 137, .2)!important;
    opacity: .95
}

.button.secondary-btn.btn-outlined {
    border-color: #344258;
    color: #344258;
    background-color: transparent
}

.button.secondary-btn.btn-outlined:hover {
    color: #fff;
    background-color: #344258
}

.button.secondary-btn:focus {
    border-color: #344258
}

@-webkit-keyframes ripple-animation {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .4
    }
    to {
        -webkit-transform: scale(100);
        transform: scale(100);
        opacity: 0
    }
}

@keyframes ripple-animation {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .4
    }
    to {
        -webkit-transform: scale(100);
        transform: scale(100);
        opacity: 0
    }
}

.flex-card {
    position: relative;
    background-color: #fff;
    border: 1px solid #fcfcfc;
    border-radius: .1875rem;
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
    transition: all 0.5s
}

.flex-card .card-body {
    padding: 30px 30px
}

.flex-card .card-body.is-large {
    padding: 40px 40px
}

.flex-card.light-bordered {
    border: 1px solid #e5e5e5!important
}

.flex-card.raised {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .2)
}

.floating-circle {
    width: 100px;
    height: 100px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 22px;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, .06)
}

.floating-circle img {
    width: 60px;
    position: relative;
    left: 20px
}

.card img {
    display: block
}

/*.modal {
    transition: all 0.5s;
    -webkit-transform: scale(0);
    transform: scale(0)
}

.modal .modal-content {
    transition: all 0.4s;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    z-index: 99!important
}

.modal .modal-close {
    transition: all 0.4s;
    z-index: 100!important
}

.modal .modal-close:hover {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

.modal a {
    border-radius: 6px
}

.modal.is-active {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.modal.is-active .modal-background {
    background: linear-gradient(45deg, #6a85b6 0%, #bac8e0 100%);
    background-color: #6fa8dc;
    width: 1000px;
    height: 1000px;
    border-radius: 10000px;
    transition: all 0.5s;
    -webkit-transform: scale(0);
    transform: scale(0);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 98!important;
    overflow: hidden!important
}

.modal.is-active .modal-background.scaleInCircle {
    -webkit-transform: scale(7);
    transform: scale(7)
}

.modal.is-active .modal-content.scaleIn {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
}

.modal.is-active .modal-close {
    z-index: 100!important
}

.modal.is-active.image-modal .modal-background {
    background: linear-gradient(45deg, #444F60, #444);
    background-color: #444F60
}

.modal.is-active.image-modal img {
    border-radius: 6px
}

.modal.is-active.image-modal .gallery-item figure {
    background: none
}

.modal.is-active .modal-card .modal-card-head,
.modal.is-active .modal-card .modal-card-foot {
    padding: 15px
}

.modal.is-active .modal-card .modal-card-head {
    background-color: #fff
}

.modal.is-active .modal-card .modal-card-head p {
    color: #444F60
}

.modal.is-active .modal-card .modal-card-head .delete {
    background: transparent!important;
    transition: all 0.3s
}

.modal.is-active .modal-card .modal-card-head .delete:before {
    background: #06f4b6
}

.modal.is-active .modal-card .modal-card-head .delete:after {
    background: #06f4b6
}

.modal.is-active .modal-card .modal-card-head .delete:hover {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

.modal.is-active .modal-card .modal-card-body::-webkit-scrollbar {
    width: 10px
}

.modal.is-active .modal-card .modal-card-body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, .2)
}

.modal.is-active .modal-card .modal-card-foot {
    background-color: #fff;
    -ms-flex-pack: end;
    justify-content: flex-end;
    border-top: none
}

.modal.is-active.modal-sm .modal-card-body {
    max-height: 400px
}

.modal.is-active.modal-hero .modal-background {
    background: linear-gradient(to right, #06f4b6, #344258);
    background-color: #344258
}

.modal.is-active.modal-success .modal-background {
    background: #00b289
}

.modal.is-active.modal-error .modal-background {
    background: #FF7273
}

.modal.is-active.modal-warning .modal-background {
    background: #eda514
}

.modal.is-active.modal-info .modal-background {
    background: #039BE5
}*/

@media screen and (min-width:769px) {
    .modal.modal-sm .modal-card,
    .modal.modal-sm .modal-content {
        width: 400px!important
    }
}

.input,
select {
    box-shadow: none!important
}

input.input {
    color: #878787;
    box-shadow: none;
    transition: all 0.8s;
    padding-bottom: 3px;
    border-color: #ccc;
    font-size: .9rem
}

input.input.is-medium {
    padding-bottom: 5px;
    font-size: 1rem;
    height: 2.5rem
}

input.input.is-large {
    padding-bottom: 7px;
    font-size: 1.25rem;
    height: 3rem
}

input.input:focus,
input.input:active {
    border-color: #EFF4F7
}

.field .control i {
    transition: all .4s
}

.field .control.required {
    position: relative
}

.field .control.required i {
    display: none;
    position: absolute;
    right: 10px;
    top: 8px
}

.field .control.required.has-error input {
    border: 1px solid #FF7273
}

.field .control.required.has-error .error i {
    display: block;
    color: #FF7273
}

.control-material {
    position: relative;
    margin-bottom: 3em
}

.control-material input {
    border-radius: 0!important
}

.control-material.is-primary input.material-input:focus~label,
.control-material.is-primary input:valid~label {
    color: #6fa8dc
}

.control-material.is-primary .bar:before,
.control-material.is-primary .bar:after {
    background: #6fa8dc
}

.control-material input.material-input {
    width: 100%!important;
    font-size: 18px;
    padding: .625em .625em .625em .3125em;
    display: block;
    width: 18.75em;
    border: none;
    border-bottom: 1px solid #A9ABAC;
    background-color: transparent!important
}

.control-material input.material-input:focus {
    outline: none
}

.control-material label {
    font-family: 'Arial';
    color: rgba(0, 0, 0, 0);
    font-size: 15px;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    left: .3125em;
    top: .625em;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.control-material input.material-input:focus~label,
.control-material input:valid~label {
    top: -20px;
    left: -2px;
    font-size: 12px
}

.control-material .bar {
    position: relative;
    display: block
}

.control-material .bar:before,
.control-material .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all
}

.control-material .bar:before {
    left: 50%
}

.control-material .bar:after {
    right: 50%
}

.control-material input.material-input:focus~.bar:before,
.control-material input.material-input:focus~.bar:after {
    width: 50%
}

.control-material.required {
    position: relative
}

.control-material.required input {
    padding-right: 25px
}

.control-material.required i {
    position: absolute;
    right: 15px;
    top: 8px;
    color: #cecece;
    display: none
}

.control-material.required.has-error input {
    border-bottom-color: #FF7273!important
}

.control-material.required.has-error .bar:after,
.control-material.required.has-error .bar:before {
    background: #FF7273!important
}

.control-material.required.has-error .error i {
    color: #FF7273!important;
    display: block
}

.control-material.required.has-error label {
    color: #FF7273!important
}

.select:after {
    border: 1px solid #6fa8dc;
    border-right: 0;
    border-top: 0
}

.select:hover:after {
    border: 1px solid #468fd2;
    border-right: 0;
    border-top: 0
}

.select select:active,
.select select:focus {
    border-color: #6fa8dc
}

.select:not(.is-multiple):not(.is-loading)::after {
    right: 1.525em;
    z-index: 4;
    font-size: .8rem;
    border: 1px solid #999;
    border-right: 0;
    border-top: 0
}

@-webkit-keyframes slideUp {
    0% {
        -webkit-transform: translateY(6.25rem);
        transform: translateY(6.25rem)
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes slideUp {
    0% {
        -webkit-transform: translateY(6.25rem);
        transform: translateY(6.25rem)
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.checkbox-wrap {
    position: relative;
    cursor: pointer;
    padding: 1em
}

.checkbox-wrap::-moz-selection {
    background: transparent
}

.checkbox-wrap::selection {
    background: transparent
}

.checkbox-wrap input.d-checkbox+span {
    background: #fff;
    content: "";
    display: inline-block;
    margin: 0 .5em 0 0;
    padding: 0;
    border: 1px solid #999;
    vertical-align: middle;
    width: 2em;
    height: 2em
}

.checkbox-wrap input.d-checkbox+span::after {
    content: "";
    display: block;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s
}

.checkbox-wrap input.d-checkbox:checked+span::after {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.checkbox-wrap input.d-checkbox {
    position: absolute;
    cursor: pointer;
    opacity: 0
}

.checkbox-wrap input+span {
    border-radius: 2px
}

.checkbox-wrap input+span::after {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwb2x5Z29uIHBvaW50cz0iMTMuNzA3LDMyLjI5MyAxMi4yOTMsMzMuODU0IDI0LjI5Myw0NiAyNS43MDcsNDYgNDkuNzA3LDIxLjg1NCA0OC4yOTMsMjAuMzY2IDI1LDQzLjYyMyAiLz48L2c+PC9zdmc+) no-repeat center;
    background-size: contain;
    width: 1.9em;
    height: 1.9em
}

.checkbox-wrap.is-medium input+span {
    width: 1.4em;
    height: 1.4em
}

.checkbox-wrap.is-medium input+span::after {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwb2x5Z29uIHBvaW50cz0iMTMuNzA3LDMyLjI5MyAxMi4yOTMsMzMuODU0IDI0LjI5Myw0NiAyNS43MDcsNDYgNDkuNzA3LDIxLjg1NCA0OC4yOTMsMjAuMzY2IDI1LDQzLjYyMyAiLz48L2c+PC9zdmc+) no-repeat center;
    background-size: contain;
    width: 1.3em;
    height: 1.3em
}

.content ul {
    list-style-type: disc
}

ul {
    list-style-type: none
}

.tag {
    margin-bottom: 5px
}

.tag.is-primary {
    background: #6fa8dc;
    color: #fff
}

.tag.is-success {
    background: #00b289;
    color: #fff
}

.tag span.delete {
    top: 2px
}

.login {
    padding: 10px 0
}


.return {
    color: #A9ABAC!important;
    transition: opacity 0.5s
}


.return:hover {
    opacity: .8;
    color: #6fa8dc!important
}

.icon.user,
.icon.password {
    margin: 5px 10px 0 0
}

.checkbox-wrap {
    color: #A9ABAC;
    margin-bottom: 10px
}

.checkbox-wrap span {
    position: relative;
    top: -2px
}

.hero-body.is-clean {
    padding-top: 0!important
}

.clean-title {
    font-family: 'Nexa Bold', sans-serif;
    font-weight: 700;
    font-size: 3.4rem;
    color: #444F60;
    padding: 0
}

@media (max-width:768px) {
    .column.caption-column .clean-title,
    .column.caption-column .subtitle {
        text-align: center!important
    }
}

.section-title-wrapper .clean-bg-symbol {
    position: absolute;
    font-weight: bolder;
    top: -25px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    font-size: 10rem;
    z-index: 0;
    opacity: .1;
    color: #999;
    width: 150px
}

.section-title-wrapper .clean-bg-symbol i {
    font-weight: 900
}

.section-title-wrapper .subtitle {
    font-family: 'Nexa Light', sans-serif;
    color: #A9ABAC
}

.clean-section-title {
    font-family: 'Nexa Bold', sans-serif;
    font-size: 2.6rem;
    color: #444F60
}

.clean-section-title:before {
    content: "";
    border-top: 3px solid #ededed;
    position: absolute;
    width: 100px;
    top: 40%;
    left: 15%;
    z-index: -999
}

.clean-section-title:after {
    content: "";
    border-top: 3px solid #ededed;
    position: absolute;
    width: 100px;
    top: 40%;
    right: 15%;
    z-index: -999
}

@media (max-width:768px) {
    .clean-section-title:after,
    .clean-section-title:before {
        display: none!important
    }
}

.clean-text {
    font-family: 'Nexa Light', sans-serif
}

.small-footer-logo {
    height: 38px
}

@media (max-width:768px) {
    .hero-body.is-clean {
        padding-top: 3rem!important
    }
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    overflow: hidden!important
}

a,
a:active,
i,
i:active,
div,
div:active,
div:focus,
span:focus,
span:active {
    outline: none!important
}

.button.is-active,
.button:active,
.button:focus {
    box-shadow: none!important
}

section:focus {
    outline: none!important
}

@font-face {
    font-family: 'Nexa Light';
    src: url(../fonts/NexaLight.woff) format("woff"), url(../fonts/NexaLight.ttf) format("truetype")
}

@font-face {
    font-family: 'Nexa Bold';
    font-weight: 700;
    src: url(../fonts/NexaBold.woff) format("woff"), url(../fonts/NexaBold.ttf) format("truetype")
}

::-webkit-input-placeholder {
    color: #cecece
}

::-moz-placeholder {
    color: #cecece
}

:-ms-input-placeholder {
    color: #cecece
}

:-moz-placeholder {
    color: #cecece
}

*::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
    width: 10px
}

*::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, .2)
}

.color-red {
    color: #FF7273!important
}

.light-text {
    color: #fff!important
}

.primary-text {
    color: #6fa8dc!important
}

.text-bold {
    font-weight: 600!important
}

.is-size-6 {
    font-size: 1rem
}

.no-padding {
    padding: 0!important
}

.padding-20 {
    padding: 20px
}

.pt-10 {
    padding-top: 10px
}

.no-margin {
    margin: 0!important
}

.ml-auto {
    margin-left: auto!important
}

.mt-10 {
    margin-top: 10px
}

.mt-20 {
    margin-top: 20px
}

.mt-40 {
    margin-top: 40px
}

.mt-80 {
    margin-top: 80px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-40 {
    margin-bottom: 40px
}

.ml-20 {
    margin-left: 20px
}

@media (max-width:767px) {
    .section-title-wrapper {
        padding: 40px 0!important
    }
    .content-wrapper {
        padding-top: 40px!important
    }
}

@media (max-width:768px) {
    
    .navbar .navbar-menu .navbar-item {
        width: 100%;
        text-align: center
    }
    .navbar .navbar-menu .button {
        width: 100%
    }
    .navbar-brand {
        min-height: 4.6rem
    }
    .level-left.level-social {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .level-left.level-social .level-item:last-child .icon i {
        position: relative!important
    }
    #main-hero {
        max-height: 100vh;
        background-position-x: 55%!important
    }
    #navigation-trigger,
    .navigation-close {
        opacity: 1!important
    }
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) {
    .columns {
        padding: 0 10%
    }
    .content-wrapper {
        padding-top: 0!important
    }
}

@media (max-width:1007px) {
    .account {
        display: none
    }
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) {
    .navbar-brand {
        min-height: 4.6rem;
        height: 4.6rem
    }
    
    .navbar .navbar-menu .navbar-item {
        width: 100%;
        text-align: center
    }
    .navbar .navbar-menu .button {
        width: 100%
    }
    .navbar .navbar-menu #signup-btn {
        width: 30%!important
    }
}