.wrapper {
  background-color: rgba(52, 169, 255, .8);
  /* position: absolute; */
  width: 100%;
  /* height: 100px; */
  height: 6.5em;

}
.banner_button {
  margin-top: 10px;
  font-size: 0.8rem;
}
.banner_container {
  margin-top: 20px;
  /* margin-left: 25%; */
}

.text_style {
  color: #fff;
  padding-top: 0px;
  /* padding-left: 30px; */
}
.text_main {
  font-size: 22px;
  font-weight: 900;
  line-height: 30px
}
.text_sub {
  font-size: 12px;
  line-height: 15px
}

.single_item {
  margin-top: 5px;
  /* margin-left: 5%; */
  /* width: 25px */
}

.close {
  color: #fff;
  cursor: pointer;
}
.banner_image {
  height: 40px;
  /* padding-top: 5px */
}
.is-small {
  font-size: 0.6rem !important;
  margin-top: 20px;

}

@media (min-width: 427px) {
  .mobile {
    display: none;
  }
 }
 @media (min-width: 800px) and (max-width: 1040px) {

  .banner_container {
    width: 130%;
  }
 }
 @media (min-width: 740px) and (max-width: 1269px) {

  .banner_container {
    /* margin-left: 10%; */
  }
 }
 @media (max-width: 800px) {
  .banner_container {
    /* margin-left: 5px; */
    margin-top: 10px;
    /* width: 130% */
  }
  .text_main {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-align: justify;
  }
  .text_sub {
    display: none
  }
  .wrapper {
    height: 80px !important
  }
 }
 @media (max-width: 426px) {
  .not_mobile {
    display: none
  }
 }

 @media (max-width: 346px) {
  .not_mobile {
    display: none
  }
  .text_main {
    line-height: 0px !important; 

  }
 }