.fa,
.material-icons {
    display: inline-block
}

@font-face {
    font-family: FontAwesome;
    src: url(../fonts/fa-regular-400.eot);
    src: url(../fonts/fontawesome-webfont.woff2?v=4.6.3);
    font-weight: 400;
    font-style: normal
}

.fa {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.fa-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.material-icons {
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.fa-heart:before {
    content: "\f004"
}

.fa-qrcode:before {
    content: "\f029"
}

.fa-twitter:before {
    content: "\f099"
}


.fa-facebook:before {
    content: "\f09a"
}

.fa-github:before {
    content: "\f09b"
}

.fa-linkedin:before {
    content: "\f0e1"
}

.fa-angle-down:before {
    content: "\f107"
}

.fa-gg:before {
    content: "\f260"
}

@font-face {
    font-family: simple-line-icons;
    src: url(../fonts/simple-line-icons.ttf);
    src: url(../fonts/simple-line-icons.woff2);
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/material-icons/MaterialIcons-Regular.eot);
    src: local('../fonts/material-icons/Material Icons'), local('../fonts/material-icons/MaterialIcons-Regular'), url(../fonts/material-icons/MaterialIcons-Regular.woff2) format('woff2'), url(../fonts/material-icons/MaterialIcons-Regular.woff) format('woff'), url(../fonts/material-icons/MaterialIcons-Regular.ttf) format('truetype')
}

.material-icons {
    font-family: 'Material Icons';
    font-size: 24px;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga'
}