.landingCustom {
  color: #444F60 !important;
  background-image: url("../../assets/images/vector16.svg");
  background-repeat: no-repeat;
  background-position-x: 120%;
  background-position-y: -5%

}

.roundedInput {
  height: 60px;
}
input[type="text"] {
  width: 70%;
  padding-right: 15% !important
}
input[type="submit"] {
  margin-left: -50px;
    /* height: 25px; */
    text-align: center;
    cursor: pointer;
    padding-left: 4% !important;
    width: 30%;
    background: #34A9FF;
    color: white;
    border: 0;
  border-radius: 0px 47px 47px 0px !important;
    -webkit-appearance: none;
}

.hero-img {
  margin-left: 20%;
}

.anotherBackgroundImg::after {
  content: " ";
  position: absolute;
  margin-top: 20%; 
  background-image: url("../../assets//images/vector18.svg");
  background-repeat: no-repeat;
  background-position-x: 400%;
  background-position-y: 100%;
  z-index: 1;
  height: 400px;
  width: 400px;

}

.dottedBoxPhone {
  background-image: url("../../assets//images/group200.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 45%
}
.rectangleImg {
  background-image: url("../../assets/images/rectangle145.svg");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 95%

}

.dottedTestimony {
  background-image: url("../../assets//images/group200.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
}
.slider_container {
  padding: 80px;
  /* background: #419be0; */
}

.slick-slide img {
  margin: auto;
}

.vector_16 {
  position: absolute;
  width: 737px;
  height: 687.5px;
  left: 717px;
  top: -35px;
  background: #F4F6FB;
}

.testimonyCard1 {
  padding: 50px;
  background-color: #E743AF;
  box-shadow: 7.47848px 7.47848px 24.9283px rgba(231, 67, 175, 0.5);
  border-radius: 4px;
  color: whitesmoke !important
}

.testimonyCard2 {
  margin-left: 3%;
  /* height: 350px; */
  padding: 50px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 7.47848px 7.47848px 24.9283px rgba(121, 121, 121, 0.11);
  backdrop-filter: blur(16.9405px);
  border-radius: 4px;
  color: #444F60 !important
}

.testimonyCard1 > p {
  margin-top: 30px;
}

.testimonyCard2 > p {
  margin-top: 30px;
}

.testimonyCard1 > span {
  height: 22px;
  margin-left: 29%;
  margin-top: -17%;
  display: block;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.testimonyCard2 > span {
  height: 22px;
  margin-left: 29%;
  margin-top: -17%;
  display: block;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.testimonyCard1 .avatar {
  /* position: absolute; */
  border-radius: 50%;
  margin-top: 7%;
  width: 58px;
  /* height: 58px */
}

.testimonyCard2 .avatar {
  /* position: absolute; */
  border-radius: 50%;
  margin-top: 7%;
  width: 58px;
  height: 58px
}

.quote-mini-landing {
  left: 2.9%;
  right: 83.81%;
  top: 46%;
}

.videoContainer {
  
  padding: 80px
}

.videoContainer::before {
  content: " ";
  margin-top: -5%;
  margin-left: 20%;
  position: absolute;
  background-image: url("../../assets/images/vector17.png");
  background-repeat: no-repeat;
  background-position: cover;
  background-size: 100%;
  background-position-x: -100%;
  background-position-y: right;
  height: 900px;
  width: 700px;
  z-index: -1;
}

.videoStyle {
  margin: 5%;
  border-radius: 10px;
  

}

.videoStyleDiv {
  margin-top: 0%;
  /* background-image: url("../../assets/images/group200.svg");
  background-position-y: left;
  background-repeat: no-repeat;
  background-position-x: 12%;
  background-position-y: 10% */
}

.newfooter {
  /* content: " "; */
  /* position: absolute; */
  background-image: url("../../assets/images/vector19.svg");
  /* background-position-y: -10%; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  /* height: 50%; */
  /* width: 70%; */
}

.bottomLinks {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  color: #5B6C94;
}

.footer-text {
  padding-left: 15px
}


@media (max-width: 768px) {
  .landing_mobile {
    display: none;
  }
 }

 .captionForm {
   /* width: 70%; */
   /* margin-left: 20% */
   padding-top: 5%
  /* {width: "70%", marginLeft: "20%", paddingTop: "5%"} */
 }

 @media (max-width: 421px) {
   .captionForm {
     width: 100%;
     
   }
 }

 @media (max-width: 320px) {
  /* .captionForm {
    width: 200%;
    margin-left: -45%
  } */
}

@media (max-width: 768px) {
  .testimonyCard2 {
    margin-left: 0% !important;
  }
}